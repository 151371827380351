import LandingPage from '@/components/pages/LandingPage.vue'
import HomeView from '@/views/HomeView.vue'

export default [
  {
    path: '/',
    name: 'landing',
    component: LandingPage,
    meta: { guestOnly: true }
  },
  {
    path: '/dashboard',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/basket',
    name: 'basketPage',
    component: () => import('../components/pages/BasketPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-detail',
    name: 'accountInfo',
    component: () => import('../views/AccountInfoView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/upcoming-tasks',
    name: 'upcomingTasks',
    component: () => import('../components/pages/UpcomingPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/new-client',
    name: 'newClient',
    component: () => import('../components/pages/ClientPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/client-search',
    name: 'clientSearch',
    component: () => import('../components/pages/ClientSearchPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../components/pages/CompanyPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invite-list',
    name: 'inviteList',
    component: () => import('../components/pages/InviteList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/worker-page',
    name: 'worker',
    component: () => import('../components/pages/WorkerPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/new-appointment',
    name: 'addReminder',
    component: () => import('../components/pages/AddReminder.vue'),
    meta: { requiresAuth: true }
  }
]
export default [
  {
    path: '/result-page',
    name: 'result-page',
    component: () => import('../components/alerts/ResultPage.vue'),
  },
  {
    path: '/error',
    name: 'ServerError',
    component: () => import('../components/alerts/ServerError.vue'),
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('../components/alerts/UnauthorizedError.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../components/alerts/NotFound.vue'),
  }
]


import { apiClient, performRequest } from '@/services/axios.interceptor'

class AuthService {

    login(user) {
        return performRequest('post', '/auth/authenticate', {
            email: user.email,
            password: user.password
        });
    }

    logout() {
        ['user', 'accessToken', 'refreshToken', 'mainPageInfo', 'basketCount', 'basket', 'basketItems'].forEach(item => localStorage.removeItem(item));
    }

    forgotPassword(email) {
        return performRequest('post', '/auth/forgot-pass', email);
    }

    register(user) {
        return performRequest('post', '/register', user);
    }

    changePassword(passwords) {
        return performRequest('post', '/change/pass', passwords);
    }

    activateUser(activationCode) {
        return performRequest('get', `/register/activate/${activationCode}`);
    }

    refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        return apiClient.post('/auth/refresh-token', refreshToken).then(response => {
            if (response.data.access_token) {
                localStorage.setItem('accessToken', response.data.access_token);
            }
            return response.data.access_token;
        }).catch(() => {
            console.log('Token is expired');
            this.logout();
        });
    }

}

export default new AuthService();
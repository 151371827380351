import { createStore } from 'vuex'
import { auth } from './auth.module'

export default createStore({
  state: {
    loading: false,
  },
  getters: {
    isLoading(state) {
      return state.loading
    },
  },
  mutations: {
    setLoading(state, boolean) {
      state.loading = boolean
    }
  },
  actions: {
    updateLoading({ commit }, boolean) {
      commit('setLoading', boolean)
    },
  },
  modules: {
    auth,
  }
})

import { performRequest } from '@/services/axios.interceptor'


class InviteService {

    inviteAlreadyUser(inviteCode) {
        return performRequest('get', `invite-already-user/${inviteCode}`);
    }

    inviteByEmail(inviteData) {
        return performRequest('post', '/invite-by-email', inviteData);
    }

    inviteList(companyId) {
        return performRequest('get', `/get-invite-list/${companyId}`);
    }

    deleteInvitedMail(companyId, inviteData) {
        return performRequest('post', `/delete-invite/${companyId}`, inviteData);
    }

    resendInvite(companyId, inviteData) {
        return performRequest('post', `/re-send-invite/${companyId}`, inviteData);
    }

}

export default new InviteService();
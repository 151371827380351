<template>
    <a-card class="card">
        <a-row>
            <a-col :span="24" class="col">
                <p>MÜŞTERİ ARAMA</p>
            </a-col>
            <a-col :span="24">
                <img src="@/assets/icons/people.png" alt="">
            </a-col>
        </a-row>
    </a-card>
</template>
<script setup>
import { ref, onBeforeMount } from 'vue';

const upcomingNumbers = ref(null)
const upcomingNumbersCalc = (eventNumber) => {
    const numberList = eventNumber?.toString().split('').map(Number)

    upcomingNumbers.value = numberList.map((num) => {
        return require(`@/assets/numbers/${num}.png`)
    })
}
onBeforeMount(() => {
    upcomingNumbersCalc(33)
})
</script>
<style scoped>
@import "@/assets/styles/home.card.style.css";
</style>
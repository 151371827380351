<template>
  <PageLayout />
</template>

<script>
import PageLayout from '@/components/common/PageLayout.vue'

export default {
  components: {
    PageLayout
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/common.style.css";
@import "@/assets/styles/constants.style.css";
</style>

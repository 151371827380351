<template>
    <a-card class="card">
        <a-row>
            <a-col :span="24" class="col">
                <p>YAKLAŞAN İŞ SAYISI</p>
            </a-col>
            <a-col :span="4">
            </a-col>
            <a-col :span="8">
                <img src="@/assets/icons/upcoming.png" alt="">
            </a-col>
            <a-col :span="8">
                <img v-for="( path, i ) in upcomingNumbers " :src="path" :key="i" class="number-icon">
            </a-col>
        </a-row>
    </a-card>
</template>
<script setup>

import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const upcomingNumbers = computed(() => {
    const reminderList = store.state.auth.mainPageInfo?.reminderList
    if (reminderList?.length) {
        const numberList = reminderList?.length.toString().split('').map(Number)
        return numberList?.map((num) => {
            if (num) {
                return require(`@/assets/numbers/${num}.png`)
            }
        })
    }
    return [require(`@/assets/numbers/0.png`)]
})

</script>
<style scoped>
@import "@/assets/styles/home.card.style.css";

.number-icon {
    width: 60px;
    margin-right: -10px;
    padding-top: 30px;
}

</style>
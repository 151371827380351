import { performRequest } from '@/services/axios.interceptor'

class NotificationService {

    getAllNotifications() {
        return performRequest('get', '/get-all-notification');
    }

    getLastTenNotifications() {
        return performRequest('get', '/get-last-10-notification');
    }

    readNotification(id) {
        return performRequest('get', '/read-my-notification/' + id);
    }
}

export default new NotificationService();
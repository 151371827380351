export default [
  {
    path: "/admin/all-plan",
    name: "plan-page",
    component: () => import("../components/pages/adminPages/PlanPage.vue"),
    meta: { requiresAuth: true, adminOnly: true },
  },
  {
    path: "/admin/company-edit",
    name: "company-edit",
    component: () =>
      import("../components/pages/adminPages/CompanyEdit.vue"),
    meta: { requiresAuth: true, adminOnly: true },
  },
];

<template>
    <div class="container">
        <div class="card" style="background-color: #c495f2;">
            <div class="heading-text-container">
                <div>
                    <div class="heading-title">Müşteri Kayıt Nedir?</div>
                </div>
            </div>
            <div class="heading-image-container">
                <div class="heading-text">Müşterileriniz ve Teknik Servisiniz için en iyi çözümü sunuyoruz. En hızlı ve en basit çözüm yöntemleri ile siz ve müşterilerinizin kayıtlarını tutuyoruz. </div>
                <img class="heading-image" src="@/assets/landingImages/heading-image.jpg" alt="" srcset="">
            </div>
        </div>
        <div class="card" style="background-color: #98d5e4;">
            <div class="heading-text-container">
                <div>
                    <div class="heading-title">Mutlu Müşteri</div>
                </div>
            </div>
            <div class="heading-image-container">
                <div class="heading-text">Müşterilerinizin cihazları için periyodik bakımlarını belirlediğiniz
                        zamanlarda biz hatırlatalım ve müşterilerinize doğru teklif ile ulaşın</div>
                <img class="heading-image" src="@/assets/landingImages/happy-customer.jpg" alt="" srcset="">
            </div>
        </div>
        <div class="card" style="background-color: #e3c24d;">
            <div class="heading-text-container">
                <div>
                    <div class="heading-title">Yapay Zeka Desteği</div>
                </div>
            </div>
            <div class="heading-image-container">
                <div class="heading-text">Yapay zeka desteği ile cihazların kronik sorunlarına erken müdehale ve önleyici bakım konularında sizlere en iyi desteği sunuyor.</div>
                <img class="heading-image" src="@/assets/landingImages/happy-customer.jpg" alt="" srcset="">
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="footer-container">
            <div class="title" v-for="item in footerList" :key="item.title">
                <a :href="item.path">{{ item.title }}</a>
            </div>
        </div>
    </div>
</template>

<script setup>

const footerList = [
    { title: 'Hakkımızda', path: '/' },
    { title: 'Bize Ulaşın', path: '/' },
    { title: 'İletişim', path: '/' }
];

</script>

<style scoped>
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 600px;
    min-height: 200px;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    padding:20px;
}


.heading-text-container {
    display: flex;
    text-align : center;
    align-items: center;
    margin: 20px;
}

.heading-title {
    text-align: left;
    font-size: 3vh;
    color: #2D2D2F;
    font-family: inter, Sans-serif;
    font-weight: 600;
}

.heading-text {
    text-align: left;
    padding-top: 1vh;
    font-size: 16px;
}

.heading-image-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.heading-image {
    max-width: 400px;
    width: 50%;
    border-radius: 20px 20px;
}


.footer {
    display: flex;
    min-height: 50px;
    margin-left: -5px;
    margin-right: -5px;
    justify-content: center;
    background-color: var(--footer-color);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.footer-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 70%;
}

.footer a {
    color: white;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
}

.title {
    text-align: center;
    padding:30px
}

@media only screen and (max-width: 660px) {
    .card{
        padding : 10px;
    }

    .heading-text-container {
        width: 100%;
        margin: auto;
        justify-content: center;
        text-align: center;
    }

    .heading-title {
        text-align: center;
        font-size: 25px;
    }

    .heading-text {
        text-align: center;
        font-size: 15px;
    }

    .heading-image {
        padding: 10px;
        border-radius: 100px;
    }

    .footer a {
        font-size: 15px;
        font-weight: 300;
    }

    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .title {
        padding: 8px 0px;
    }
}
</style>
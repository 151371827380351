<template>
    <a-card class="card">
        <a-row>
            <a-col :span="24" class="col">
                <p>YENİ MÜŞTERİ</p>
            </a-col>
            <a-col :span="24">
                <img src="@/assets/icons/new-client.png" alt="">
            </a-col>
        </a-row>
    </a-card>
</template>
<script setup>

</script>
<style scoped>
@import "@/assets/styles/home.card.style.css";
</style>
import userService from '@/services/user.service';
import AuthService from '../services/auth.service';
import basketService from '@/services/basket.service';

const accessToken = localStorage.getItem('accessToken');
const user = JSON.parse(localStorage.getItem('user'));
const mainPageInfo = JSON.parse(localStorage.getItem('mainPageInfo'));
const basketCount = localStorage.getItem('basketCount');
const basket = JSON.parse(localStorage.getItem('basket'));
const initialState = accessToken
    ? { status: { loggedIn: true }, user, accessToken: accessToken, mainPageInfo, basketCount, basket }
    : { status: { loggedIn: false }, user: null, accessToken: null };

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                res => {
                    let data = res.result

                    localStorage.setItem('user', JSON.stringify(data.user))
                    commit('setUser', data.user)

                    localStorage.setItem('accessToken', data.access_token)
                    localStorage.setItem('refreshToken', data.refresh_token)
                    commit('loginSuccess', data);
                    return Promise.resolve(res);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            ).then(() => {
                userService.getMainPageInfo().then((res) => {
                    commit('setMainPageInfo', res.result)
                    localStorage.setItem('mainPageInfo', JSON.stringify(res.result))
                })
            }).then(() => {
                basketService.getBasketCount().then((res) => {
                    commit('setBasketCount', res.result)
                    localStorage.setItem('basketCount', res.result)
                })
            }).then(() => {
                basketService.getMyBasket().then((res) => {
                    commit('setBasket', res.result)
                    localStorage.setItem('basket', JSON.stringify(res.result))
                })
            })
        },
        mainPageInfo({ commit }) {
            userService.getMainPageInfo().then((res) => {
                commit('setMainPageInfo', res.result)
                localStorage.setItem('mainPageInfo', JSON.stringify(res.result))
            })
        },
        basketCount({ commit }) {
            basketService.getBasketCount().then((res) => {
                commit('setBasketCount', res.result)
                localStorage.setItem('basketCount', res.result)
            })
        },
        basket({ commit }) {
            basketService.getMyBasket().then((res) => {
                commit('setBasket', res.result)
                localStorage.setItem('basket', JSON.stringify(res.result))
            })
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        refreshToken({ commit }) {
            return AuthService.refreshToken().then(
                accessToken => {
                    commit('refreshTokenSuccess', accessToken);
                    return Promise.resolve(accessToken);
                },
                error => {
                    commit('refreshTokenFailure');
                    return Promise.reject(error);
                }
            );
        },
        updateUser({ commit }, user) {
            localStorage.setItem('user', JSON.stringify(user))
            commit('setUser', user)
        }
    },
    mutations: {
        loginSuccess(state, token) {
            state.status.loggedIn = true;
            state.accessToken = token.access_token;
            state.refreshToken = token.refresh_token
        },
        setUser(state, user) {
            state.user = {...user}
        },
        setMainPageInfo(state, mainPageInfo) {
            state.mainPageInfo = {...mainPageInfo}
        },
        setBasketCount(state, basketCount) {
            state.basketCount = basketCount
        },
        setBasket(state, basket) {
            state.basket = basket
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.accessToken = null;
            state.user = null;
            state.refreshToken = null;
        },
        refreshTokenSuccess(state, accessToken) {
            state.accessToken = accessToken;
        },
        refreshTokenFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
            state.accessToken = null;
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        }
    },
    getters: {
        user() {
            return user
        },
        mainPageInfo() {
            return mainPageInfo
        },
        isAuthenticated(state) {
            return state.status.loggedIn && !!state.accessToken;
        },
        getToken(state) {
            return state.user ? state.accessToken : null;
        },
        isAdmin(state) {
            return state.user?.roles?.some((role) => role.name === "ADMIN");
        },
    }
};